import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react'
import classNames from 'classnames';

// Partials
import Loader from '/views/partials/loader';
import EmptyMessage from '/views/partials/empty-message'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Context
import useHeader from '../hooks/use-header';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';
import useVisible from '@wearetla/tla-essentials-tools/hooks/visible';

// Functions
import processAutocompleteResults from '../functions/process-autocomplete-results';

// Services
import productServices from '/services/product';

const Search = (({ className, inSearchMenu = false, onSubmit, onAutocompleteResultClick, onButtonClick }) => {
	const { sysParameters } = useGlobalState();
	const { redirect, activeRoute } = useNavigator();
	const { mobile } = useBreakpoints();
	const { setShowMobileSearchMenu } = useHeader();

	const searchContainer = useRef();
	const searchInput = useRef();

	const [initialized, setInitialized] = useState(false);
	const [defaultSearchText, setDefaultSearchText] = useState(false);
	const [searchText, setSearchText] = useState(activeRoute?.match?.params?.search ?? '');
	const [invalidInput, setInvalidInput] = useState(false);
	const [showAutocomplete, setShowAutocomplete] = useState(false);

	const searchInputChange = (e) => {
		if(!/[“!^+%&?*=_“<>(){};\\]/i.test(e.target.value)){
			setSearchText(e.target.value);
			setInvalidInput(false);
		}
		else if(e.target.value.length === 0) {
			setShowAutocomplete(false);
		}
	}

	const searchInputKeyPress = (e) => {
		if(e.keyCode === 27) {
			setShowAutocomplete(false);
		}
	}

	const bodyClickEvent = (e) => {
		if(searchContainer.current && !e.target.classList.contains('search-submitbtn') && searchContainer.current.contains(e.target)) {
			setShowAutocomplete(true)
		}
		else {
			setShowAutocomplete(false);
		}
	}

	const submit = (e) => {
		e.preventDefault();

		if(searchText.replace(/[|“!^+%&?*=_“<>(){};\\.-]/ig, '').length > 1) {
			setInvalidInput(false);
			setShowAutocomplete(false);
			redirect('search', { search: searchText });
			if(onSubmit) {
				onSubmit();
			}
		}
		else if(searchText === '' && defaultSearchText) {
			if(sysParameters['search_data_url'] && sysParameters['search_data_url'].description !== '') {
				redirect(sysParameters['search_data_url'].description);
			}
			else {
				redirect('search', { search: defaultSearchText });
			}
			if(onSubmit) {
				onSubmit();
			}
		}
		else {
			setInvalidInput(true);
		}
	}
	
	useEffect(() => {
		setDefaultSearchText(sysParameters?.['search_data_title']?.description ? sysParameters['search_data_title'].description : false);
	}, [sysParameters])

	useEffect(() => {
		if(!inSearchMenu) {
			window.document.body.addEventListener('click', bodyClickEvent);
		}

		// if(inSearchMenu && searchInput.current) {
		// 	searchInput.current.focus();
		// }

		return () => {
			if(!inSearchMenu) {
				window.document.body.removeEventListener('click', bodyClickEvent);
			}
		}
	}, [inSearchMenu])

	useEffect(() => {
		if(activeRoute?.key === 'search') {
			setSearchText(activeRoute?.match?.params?.search ?? '');
		}
		else {
			setSearchText('');
		}
	}, [activeRoute])

	return (
		<>
			<div className={classNames('section', 'header-search', className, { invalid: invalidInput })}>
				{(!mobile || inSearchMenu) ?
					<form
						ref={searchContainer}
						className={`search-form${(searchText.length > 1 || defaultSearchText) ? ' full' : ''}`}
						onSubmit={submit}>
						<div className="form-innerwrap">
							<Icon className="search-icon" name="search" />
							<input
								ref={searchInput}
								type="text"
								className="search-input"
								placeholder={defaultSearchText ? defaultSearchText : 'Ara'}
								onKeyUp={searchInputKeyPress}
								value={searchText}
								onClick={() => { setInitialized(true); }}
								onChange={searchInputChange} />
							<button type="submit" className="search-submitbtn">ara</button>
						</div>
						<SearchAutocomplete
							mobile={mobile}
							initialized={initialized}
							show={inSearchMenu ? true : showAutocomplete}
							onChangeShowState={setShowAutocomplete}
							onResultClick={() => {
								setShowAutocomplete(false);
								if(onAutocompleteResultClick) {
									onAutocompleteResultClick();
								}
							}}
							searchText={searchText} />
					</form>
					:
					<button
						onClick={() => {
							if(onButtonClick) {
								onButtonClick();
							}
							else {
								const fakeInput = document.createElement('input');
								fakeInput.setAttribute('type', 'text');
								fakeInput.style.position = 'absolute';
								fakeInput.style.opacity = 0;
								fakeInput.style.height = 0;
								fakeInput.style.fontSize = '16px';
								document.body.prepend(fakeInput);
								fakeInput.focus();
								setShowMobileSearchMenu(true);

								setTimeout(() => {
									document.querySelector('.search-input').focus();
								}, 100);
							}
						}}
						type="button"
						className="search-triggerbtn">
						<Icon className="search-icon" name="search" />
						{searchText && searchText !== '' ? searchText : (defaultSearchText ? defaultSearchText : 'Ara')}
					</button>
				}
			</div>
		</>
	)
});

Search.propTypes = {
	className: PropTypes.string,
	inSearchMenu: PropTypes.bool,
	onShowMobileMenu: PropTypes.func,
	onSubmit: PropTypes.func,
	onAutocompleteResultClick: PropTypes.func,
}

const SearchAutocomplete = ({ searchText, show, onChangeShowState, onResultClick, initialized }) => {
	const { mobile } = useBreakpoints();
	const { popularSearches } = useHeader();
	const { getRoutePath } = useNavigator();

	const _mounted = useRef(false);

	const activeSearchText = useRef('');

	const [results, setResults] = useState(false);

	const debouncedText = useDebounce(searchText, 300);
	const [inDom, visible] = useVisible(show);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {	
		if(initialized) {
			if(debouncedText && debouncedText.replace(/[|“!^+%&?*=_“<>(){};\\. -]/ig, '').length > 1) {
				if(results === false || activeSearchText.current !== debouncedText) {
					activeSearchText.current = debouncedText;
					if(_mounted.current) {
						setResults(null);
					}
	
					productServices.getAutocompleteResults(debouncedText).then((payload) => {
						if(activeSearchText.current === debouncedText) {
							
							let newResults = [];
	
							if(payload.brands.length) {
								newResults.push({
									// title: 'Markalar',
									type: 'regular',
									items: processAutocompleteResults(payload.brands, 'brand', activeSearchText, getRoutePath),
								});
							}
							if(payload.categories.length) {
								newResults.push({
									// title: 'Kategoriler',
									type: 'regular',
									items: processAutocompleteResults(payload.categories, 'category', activeSearchText, getRoutePath),
								});
							}
							if(payload.products.length) {
								newResults.push({
									// title: 'Ürünler',
									type: 'regular',
									items: processAutocompleteResults(payload.products, 'product', activeSearchText, getRoutePath),
								});
							}
	
							if(_mounted.current) {
								setResults(newResults);
								onChangeShowState(true);
							}
						}
					}).catch(() => {
						if(_mounted.current) {
							setResults(false);
							onChangeShowState(false);
						}
					})
				}
			}
			else {
				if(_mounted.current) {
					if(debouncedText.length > 0) {
						if(!(results && results.length === 0)) {
							activeSearchText.current = debouncedText;
							setResults([]);
						}
					}
					else {
						setResults(false);
						onChangeShowState(false);
					}
				}
			}
		}	
	}, [debouncedText, results, onChangeShowState, initialized])

	if(inDom) {
		return (
			<div className={`search-autocomplete${visible ? ' show' : ''}`}>
				<SimpleBar className="autocomplete-outerwrap">
					<div className={`autocomplete-innerwrap loader-container${mobile ? '' : ' low'}`}>
						<Loader loading={results === null || (results === false && !popularSearches)} />
						<ResultsList onResultClick={onResultClick} results={results === false ? popularSearches : results} />
					</div>
				</SimpleBar>
			</div>
		)
	}
	else {
		return false;
	}
}

SearchAutocomplete.propTypes = {
	searchText: PropTypes.string,
	show: PropTypes.bool,
	onChangeShowState: PropTypes.func,
	onResultClick: PropTypes.func,
}

const ResultsList = ({results = false, onResultClick}) => {
	if(!results) {
		return null;
	}
	else if(results.length > 0) {
		return (
			<>
				{results.map((group, nth) => (
					<div
						className={`autocomplete-group type-${group.type}`}
						key={nth}>
						{group.title &&
							<strong className="group-title">{group.title}</strong>
						}
						<ul className="group-results">
							{group.items.map((result, nth) => (
								<li key={nth} className="results-result">
									<Link
										raw
										href={result.href}
										onClick={onResultClick}
										className={`result-link${group.type === 'label' ? ' label' : ''}`}>
										{group.type === 'label' ?
											<>{result.name}</>
											:
											<>
												{(result.image && result.image_types) &&
													<Img className="result-image" src={result.image_types.mini + result.image.url} />
												}
												<span
													className="result-title"
													dangerouslySetInnerHTML={{__html: result.name}} />
												{!group.title &&
													<span className="result-type">
														{result.type}
													</span>
												}
											</>
										}
									</Link>
								</li>
							))}
						</ul>
					</div>
				))}
			</>
		)
	}
	else if(results) {
		return (
			<EmptyMessage>Sonuç Bulunamadı</EmptyMessage>
		)
	}
}

ResultsList.propTypes = {
	results: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
	onResultClick: PropTypes.func,
}

export default Search;